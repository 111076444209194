import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  TextField,
  Button,
  Typography,
  Link,
  CircularProgress
} from "@material-ui/core"

import isEmail from "validator/lib/isEmail"
import { navigate } from "gatsby"
import { Auth, API } from "aws-amplify"
import queryString from "query-string"
import AppForm from "./AppForm"
import FormFeedback from "./formFields/FormFeedback"
import { getCurrentUser } from "../utils/modelService"

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(6)
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
      //fontWeight: "bold"
    },
    reVerifyButton: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[100]
    },
    feedback: {
      marginTop: theme.spacing(2)
    },
    dropDown: {
      marginTop: 18
    },
    buttonProgress: {
      // color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  })

interface ContactUsProps extends WithStyles<typeof styles> {
  onSignedIn?: () => void
}

const formVal = {
  value: "",
  isDirty: false,
  errorMessage: ""
}

const ContactUs = (props: ContactUsProps) => {
  const { classes } = props
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [formFields, setFormFields] = React.useState({
    name: { ...formVal },
    email: { ...formVal },
    subject: { ...formVal },
    body: { ...formVal }
  })
  const [feedBackError, setFeedBackError] = React.useState("")
  const [feedBackSuccess, setFeedBackSuccess] = React.useState("")
  const [formDisabled, setFormDisabled] = React.useState(false)
  const [spinnerVisible, SetSpinnerVisible] = React.useState(false)

  React.useEffect(() => {
    getCurrentUser().then(currentUser => {
      if (!currentUser) return
      var allFields = {
        ...formFields,
        email: {
          ...formVal,
          value: currentUser.attributes.email
        }
      }
      setFormFields(allFields)
    })
  }, [])

  React.useEffect(() => {
    let formIsValid = true
    for (let key in formFields) {
      if (!!formFields[key].errorMessage || !formFields[key].value) {
        formIsValid = false
        break
      }
    }
    setSubmitDisabled(!formIsValid)
  }, [formFields])

  const formHandler = e => {
    const newForState = {
      ...formFields,
      [e.target.name]: {
        value: e.target.value,
        isDirty: true,
        errorMessage: validate(e.target.name, e.target.value)
      }
    }
    setFormFields(newForState)
  }

  const validate = (name: string, value: string) => {
    if (["name", "email", "subject", "body"].indexOf(name) >= 0) {
      if (value.length == 0) return "field required"
      if (name == "email") {
        return !isEmail(value.trim()) ? "Invalid email" : null
      }
    }
    return ""
  }

  const submit = async () => {
    try {
      setFormDisabled(true)
      SetSpinnerVisible(true)
      const result = await API.post("emailapi", "/email", {
        headers: {
          "Content-Type": "application/json"
        },
        body: {
          email: formFields.email.value,
          subject: formFields.subject.value,
          body: `<p>${formFields.name.value}</p> <p>${formFields.body.value}</p>`
        }
      })
      SetSpinnerVisible(false)
      setFeedBackSuccess("Thank you for your message")
      setFeedBackError("")
    } catch (err) {
      setFeedBackSuccess("")
      setFeedBackError("")
      setSubmitDisabled(true)
      console.error(err)
    }
  }

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom align="center">
            Contact Us
          </Typography>
        </React.Fragment>
        <form className={classes.form} noValidate>
          <TextField
            autoComplete="name"
            disabled={formDisabled}
            placeholder="Name"
            label={formFields.name.errorMessage || "Name"}
            error={!!formFields.name.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            margin="normal"
            name="name"
            required
            fullWidth
            value={formFields.name.value}
          />

          <TextField
            autoComplete="email"
            disabled={formDisabled}
            placeholder="Email"
            label={formFields.email.errorMessage || "Email"}
            error={!!formFields.email.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            margin="normal"
            name="email"
            required
            fullWidth
            value={formFields.email.value}
          />

          <TextField
            placeholder="Subject"
            label={formFields.subject.errorMessage || "Subject"}
            error={!!formFields.subject.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            fullWidth
            disabled={formDisabled}
            required
            name="subject"
            autoComplete="subject"
            type="text"
            margin="normal"
          />

          <TextField
            placeholder="Message"
            label={formFields.body.errorMessage || "Message"}
            error={!!formFields.body.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            fullWidth
            disabled={formDisabled}
            required
            name="body"
            autoComplete="Message"
            type="text"
            margin="normal"
            multiline
            rowsMax="8"
          />

          <Button className={classes.button} disabled={submitDisabled || formDisabled} fullWidth onClick={submit}>
            {"send"}
          </Button>
          {spinnerVisible && <CircularProgress size={24} className={classes.buttonProgress} />}
        </form>
        <FormFeedback error={!!feedBackError} className={classes.feedback} success={!!feedBackSuccess}>
          {feedBackError || feedBackSuccess}
        </FormFeedback>
      </AppForm>
    </React.Fragment>
  )
}
export default withStyles(styles)(ContactUs)
